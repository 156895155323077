<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library as fontAwesomeIconLibrary } from '@fortawesome/fontawesome-svg-core';
import camelCase from 'lodash/camelCase';
import { faSync, faUser } from '@fortawesome/free-solid-svg-icons'

// https://fontawesome.com/icons
fontAwesomeIconLibrary.add(faUser, faSync);

export default {
	components: {
		FontAwesomeIcon,
	},
	props: {
		source: {
			type: String,
			default: 'font-awesome',
		},
		name: {
			type: String,
			required: true,
		},
	},
	computed: {
		// Gets a CSS module class, e.g. iconCustomLogo
		customIconClass() {
			return this.$style[camelCase('icon-custom-' + this.name)];
		},
	},
};
</script>

<template>
	<FontAwesomeIcon v-if="source === 'font-awesome'" v-bind="$attrs" :icon="name" />
	<span v-else-if="source === 'custom'" v-bind="$attrs" :class="customIconClass" />
</template>
