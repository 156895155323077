import appConfig from '@src/app.config.ES6';

export default {
	// Application Version
	APP_VERSION: '1.6.2',

	// PUBLIC endpoints
	PUBLIC_RECIPE_BY_ID_URL: `${appConfig.API_BASE_URL}/api/publics/recipe/:id`,

	GET_USER_PROFILE_URL: `${appConfig.API_BASE_URL}/api/profiles/current`,
	UPDATE_USER_PROFILE_URL: `${appConfig.API_BASE_URL}/api/profiles/current/update`,

	SIGNUP_URL: `${appConfig.API_BASE_URL}/api/signup`,

	USER_LOGIN_URL: `${appConfig.API_BASE_URL}/api/Accounts/login`,
	ADMIN_LOGIN_URL: `${appConfig.API_BASE_URL}/api/Accounts/admin/login`,
	VALIDATE_AUTH_TOKEN_URL: `${appConfig.API_BASE_URL}/api/auth/token`,

	AUTOCOMPLETE_LISTS_URL: `${appConfig.API_BASE_URL}/api/admin/autoCompleteLists`,

	// INGREDIENT ENDPOINTS
	MANAGE_INGREDIENTS_URL: `${appConfig.API_BASE_URL}/api/admin/manageIngredients`,
	INGREDIENT_DETAILS_URL: `${appConfig.API_BASE_URL}/api/admin/ingredient/:id/details`,
	ADD_INGREDINT_URL: `${appConfig.API_BASE_URL}/api/admin/ingredient/add`,
	UPDATE_INGREDINT_URL: `${appConfig.API_BASE_URL}/api/admin/ingredient/:id/update`,
	ADD_INGREDINT_BRANDING_URL: `${appConfig.API_BASE_URL}/api/admin/ingredient/:id/brand-ingredient/add`,
	UPDATE_INGREDINT_BRANDING_URL: `${appConfig.API_BASE_URL}/api/admin/ingredient/:id/brand-ingredient/update`,
	DELETE_INGREDINT_BRANDING_URL: `${appConfig.API_BASE_URL}/api/admin/ingredient/:id/brand-ingredient/:brandIngredientId`,

	UPDATE_MAPPED_INGREDIENT_URL: `${appConfig.API_BASE_URL}/api/admin/mappedIngredient/:id/update`,

	// RECIPE ENDPOINTS
	RECIPES_SEARCH_OPTION_URL: `${appConfig.API_BASE_URL}/api/admin/getRecipeSearchOptions`,
	MANAGE_RECIPES_URL: `${appConfig.API_BASE_URL}/api/admin/manageRecipes?params=:queryString`,
	PUBLISHED_RECIPES_URL: `${appConfig.API_BASE_URL}/api/admin/publishedRecipes?params=:queryString`,
	DRAFT_RECIPES_URL: `${appConfig.API_BASE_URL}/api/admin/draftRecipes?params=:queryString`,
	UNUSED_RECIPES_URL: `${appConfig.API_BASE_URL}/api/admin/unusedRecipes?params=:queryString`,
	FLAGGED_RECIPES_URL: `${appConfig.API_BASE_URL}/api/admin/flaggedRecipes?params=:queryString`,
	ARCHIVED_RECIPES_URL: `${appConfig.API_BASE_URL}/api/admin/archivedRecipes?params=:queryString`,

	RECIPE_DETAILS_URL: `${appConfig.API_BASE_URL}/api/admin/recipe/:id/details`,
	SAVE_RECIPE_DETAILS_URL: `${appConfig.API_BASE_URL}/api/admin/recipe/:id/update`,
	BULK_SAVE_RECIPE_DETAILS_URL: `${appConfig.API_BASE_URL}/api/admin/recipe/bulkUpdate`,
	SCRAPE_RECIPE_SOURCE_IMAGE_URL: `${appConfig.API_BASE_URL}/api/admin/recipe/:id/scrapeSourceImage`,
	CALCULATE_RECIPE_NUTRITION_URL: `${appConfig.API_BASE_URL}/api/admin/importNutrition/recipe/:id`,
	RESET_RECIPE_NUTRITION_URL: `${appConfig.API_BASE_URL}/api/admin/resetNutrition/recipe/:id`,
	UPDATE_RECIPE_NUTRITION_UNITS: `${appConfig.API_BASE_URL}/api/admin/recipe/:id/updateNutrition`,

	IMPORT_RECIPES_URL: `${appConfig.API_BASE_URL}/api/admin/importRecipes`,

	// MEAL ENDPOINTS
	MANAGE_MEALS_URL: `${appConfig.API_BASE_URL}/api/admin/manageMeals`,
	MEAL_DETAILS_URL: `${appConfig.API_BASE_URL}/api/meals/:id/details`,
	UPDATE_MEAL_URL: `${appConfig.API_BASE_URL}/api/admin/meal/:id/update`,
	ADD_MEAL_URL: `${appConfig.API_BASE_URL}/api/admin/meal/add`,
	BULK_UPDATE_MEALS_URL: `${appConfig.API_BASE_URL}/api/admin/meals/update`,
	UPDATE_MEAL_RECIPES_URL: `${appConfig.API_BASE_URL}/api/admin/meal/:id/updateRecipes`,

	// PLATELIST ENDPOINTS
	MANAGE_PLATELISTS_URL: `${appConfig.API_BASE_URL}/api/Publics/platelistDiscovery?filterResults=false`,
	PLATELIST_PAYLOAD_URL: `${appConfig.API_BASE_URL}/api/admin/platelist/:id/payload?includeMeals=false`,
	ADMIN_PLATELIST_DAYS_URL: `${appConfig.API_BASE_URL}/api/profiles/admin/platelist/findOrCreate`,
	ADMIN_PLATELIST_CREATE_URL: `${appConfig.API_BASE_URL}/api/profiles/admin/platelist/create`,
	ADMIN_PLATELIST_UPDATE_URL: `${appConfig.API_BASE_URL}/api/profiles/admin/platelist/updateMetadata`,
	ADMIN_UPDATE_PLATELIST_DISH_URL: `${appConfig.API_BASE_URL}/api/profiles/admin/platelist/dish`,

	// PLATELIST CATEGORY ENDPOINTS
	MANAGE_PLATELIST_CATEGORIES_URL: `${appConfig.API_BASE_URL}/api/admin/managePlatelistCategories`,
	UPDATE_PLATELIST_CATEGORY_URL: `${appConfig.API_BASE_URL}/api/admin/platelistCategory/:id/update`,
	ADD_PLATELIST_CATEGORY_URL: `${appConfig.API_BASE_URL}/api/admin/platelistCategory/add`,
	DELETE_PLATELIST_CATEGORY_URL: `${appConfig.API_BASE_URL}/api/admin/platelistCategory/:id/delete`,

	// TAG ENDPOINTS
	MANAGE_TAGS_URL: `${appConfig.API_BASE_URL}/api/admin/manageTags`,
	UPDATE_TAG_URL: `${appConfig.API_BASE_URL}/api/admin/tag/:id/update`,
	ADD_TAG_URL: `${appConfig.API_BASE_URL}/api/admin/tag/add`,
	DELETE_TAG_URL: `${appConfig.API_BASE_URL}/api/admin/tag/:id/delete`,

	// HEALTH ENDPOINTS
	MANAGE_HEALTHS_URL: `${appConfig.API_BASE_URL}/api/admin/manageHealths`,
	UPDATE_HEALTH_URL: `${appConfig.API_BASE_URL}/api/admin/health/:id/update`,
	ADD_HEALTH_URL: `${appConfig.API_BASE_URL}/api/admin/health/add`,
	DELETE_HEALTH_URL: `${appConfig.API_BASE_URL}/api/admin/health/:id/delete`,

	// DIET ENDPOINTS
	MANAGE_DIETS_URL: `${appConfig.API_BASE_URL}/api/admin/manageDiets`,
	UPDATE_DIET_URL: `${appConfig.API_BASE_URL}/api/admin/diet/:id/update`,
	ADD_DIET_URL: `${appConfig.API_BASE_URL}/api/admin/diet/add`,
	DELETE_DIET_URL: `${appConfig.API_BASE_URL}/api/admin/diet/:id/delete`,

	// METRIC ENDPOINTS
	MANAGE_METRICS_URL: `${appConfig.API_BASE_URL}/api/admin/manageMetrics`,
	UPDATE_METRIC_URL: `${appConfig.API_BASE_URL}/api/admin/metric/:id/update`,
	ADD_METRIC_URL: `${appConfig.API_BASE_URL}/api/admin/metric/add`,
	DELETE_METRIC_URL: `${appConfig.API_BASE_URL}/api/admin/metric/:id/delete`,

	// CATEGORY ENDPOINTS
	MANAGE_INGREDIENT_CATEGORIES_URL: `${appConfig.API_BASE_URL}/api/admin/manageIngredientCategories`,
	UPDATE_INGREDIENT_CATEGORY_URL: `${appConfig.API_BASE_URL}/api/admin/ingredient-category/:id/update`,
	ADD_INGREDIENT_CATEGORY_URL: `${appConfig.API_BASE_URL}/api/admin/ingredient-category/add`,
	DELETE_INGREDIENT_CATEGORY_URL: `${appConfig.API_BASE_URL}/api/admin/ingredient-category/:id/delete`,

	// BRAND ENDPOINTS
	MANAGE_BRANDS_URL: `${appConfig.API_BASE_URL}/api/admin/manageBrands`,
	UPDATE_BRAND_URL: `${appConfig.API_BASE_URL}/api/admin/brand/:id/update`,
	ADD_BRAND_URL: `${appConfig.API_BASE_URL}/api/admin/brand/add`,
	DELETE_BRAND_URL: `${appConfig.API_BASE_URL}/api/admin/brand/:id/delete`,

	// KITCHEN ENDPOINTS
	MANAGE_KITCHENS_URL: `${appConfig.API_BASE_URL}/api/admin/manageKitchens`,
	UPDATE_KITCHEN_URL: `${appConfig.API_BASE_URL}/api/admin/kitchen/:id/update`,
	ADD_KITCHEN_URL: `${appConfig.API_BASE_URL}/api/admin/kitchen/add`,
	DELETE_KITCHEN_URL: `${appConfig.API_BASE_URL}/api/admin/kitchen/:id/delete`,

	// IMAGE UPLOAD ENDPOINT
	GET_IMAGE_UPLOAD_URL: `${appConfig.API_BASE_URL}/api/images/Sign`,

	PROFILES: 'profiles/',
	CURRENT: 'current/',
};
