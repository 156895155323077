<template>
	<button class="button" v-bind="$attrs">
		<slot />
	</button>
</template>

<style lang="scss" scoped>
@use '@design' as *;

.button {
	box-sizing: border-box;
	padding: $size-button-padding;
	font-size: $typography-base-font-size;
	font-weight: normal;
	color: $color-white;
	cursor: pointer;
	background: $color-action;
	border: 0.3rem solid $color-action;
	border-radius: 2.5rem;

	&:focus {
		outline: none;
	}

	&:disabled {
		cursor: not-allowed;
		background: $color-button-disabled-bg;
	}

	&.button--secondary--darkbg {
		background-color: rgba($color-action, 0);
		border: 0.3rem solid $color-action;
		border-radius: 10rem;

		span {
			height: 4rem;
			font-size: 1.5rem;
			font-weight: bold;
			line-height: 2rem;
			letter-spacing: 0.42px;
		}
	}

	&.button--secondary--lightbg {
		color: $color-darkGrey;
		background-color: transparent;
		border: 0.3rem solid $color-action;
		border-radius: 10rem;
		&--noborder {
			border: none;
			&:disabled {
				border: none;
			}
		}

		span {
			font-size: 1.5rem;
			font-weight: bold;
			line-height: 1.5rem;
			letter-spacing: 0.42px;
		}

		&:disabled {
			border: 0.3rem solid rgba($color-action, 0.5);
			span {
				color: $color-darkGrey;
				opacity: 0.5;
			}
		}
	}
}
</style>
