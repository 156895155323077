import useAuthStore from '@state/auth.js';
import publicRoutes from './routes-public';

export default [
	...publicRoutes,
	{
		path: '/',
		name: 'home',
		component: () => import('@views/home.vue'),
		meta: {
			beforeResolve(routeTo, routeFrom, next) {
				const authStore = useAuthStore();
				// If the user is already logged in
				if (authStore.loggedIn) {
					// Redirect to the home page instead
					next('/recipes');
				} else {
					// Continue to the login page
					next({ name: 'login' });
				}
			},
		},
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('@views/login.vue'),
		meta: {
			beforeResolve(routeTo, routeFrom, next) {
				const authStore = useAuthStore();
				// If the user is already logged in
				if (authStore.loggedIn) {
					// Redirect to the home page instead
					next('/');
				} else {
					// Continue to the login page
					next();
				}
			},
		},
	},
	{
		path: '/profile',
		name: 'profile',
		component: () => import('@views/profile.vue'),
		meta: {
			authRequired: true,
		},
		props: () => ({ user: useAuthStore().currentUser || {} }),
	},
	{
		path: '/profile/:username',
		name: 'username-profile',
		component: () => import('@views/profile.vue'),
		meta: {
			authRequired: true,
			beforeResolve(routeTo, routeFrom, next) {
				const authStore = useAuthStore();
				authStore
					.fetchUser({ username: routeTo.params.username })
					.then((user) => {
						// Add the user to the route params, so that it can
						// be provided as a prop for the view component below.
						routeTo.params.user = user;
						// Continue to the route.
						next();
					})
					.catch(() => {
						// If a user with the provided username could not be
						// found, redirect to the 404 page.
						next({ name: '404', params: { resource: 'User' } });
					});
			},
		},
		// Set the user from the route params, once it's set in the
		// beforeResolve route guard.
		props: (route) => ({ user: route.params.user }),
	},
	{
		path: '/logout',
		name: 'logout',
		meta: {
			authRequired: true,
			beforeResolve(routeTo, routeFrom, next) {
				const authStore = useAuthStore();
				authStore.logOut();
				const authRequiredOnPreviousRoute = routeFrom.matched.some((route) => route.meta.authRequired);
				// Navigate back to previous page, or home as a fallback
				next(authRequiredOnPreviousRoute ? { name: 'login' } : { ...routeFrom });
			},
		},
	},
	{
		path: '/ingredients',
		name: 'manageIngredient',
		component: () => import('@views/ingredients/manage.vue'),
		meta: {
			authRequired: true,
		},
	},
	{
		path: '/ingredient-mappings/internal',
		name: 'internalMappedIngredients',
		component: () => import('@views/home.vue'),
		meta: {
			authRequired: true,
		},
	},
	{
		path: '/ingredient-mappings/external',
		name: 'externalMappedIngredients',
		component: () => import('@views/home.vue'),
		meta: {
			authRequired: true,
		},
	},
	{
		path: '/recipes',
		name: 'managedRecipes',
		component: () => import('@views/recipes/manage.vue'),
		meta: {
			authRequired: true,
		},
	},
	{
		path: '/recipes/published',
		name: 'publishedRecipes',
		component: () => import('@views/recipes/manage.vue'),
		meta: {
			authRequired: true,
		},
	},
	{
		path: '/recipes/drafts',
		name: 'draftRecipes',
		component: () => import('@views/recipes/manage.vue'),
		meta: {
			authRequired: true,
		},
	},
	{
		path: '/recipes/unused',
		name: 'unusedRecipes',
		component: () => import('@views/recipes/manage.vue'),
		meta: {
			authRequired: true,
		},
	},
	{
		path: '/recipes/flagged',
		name: 'flaggedRecipes',
		component: () => import('@views/recipes/manage.vue'),
		meta: {
			authRequired: true,
		},
	},
	{
		path: '/recipes/archived',
		name: 'archivedRecipes',
		component: () => import('@views/recipes/manage.vue'),
		meta: {
			authRequired: true,
		},
	},
	{
		path: '/plans',
		name: 'managePlans',
		component: () => import('@views/platelists/manage.vue'),
		meta: {
			authRequired: true,
		},
		props: () => ({
			onlyPublished: false,
			onlyDraft: false,
		}),
	},
	// {
	// 	path: '/plans/published',
	// 	name: 'publishedPlans',
	// 	component: () => import('@views/platelists/manage.vue'),
	// 	meta: {
	// 		authRequired: true,
	// 	},
	// 	props: (route) => ({
	// 		onlyPublished: true,
	// 		onlyDraft: false,
	// 	}),
	// },
	// {
	// 	path: '/plans/drafts',
	// 	name: 'draftPlans',
	// 	component: () => import('@views/platelists/manage.vue'),
	// 	meta: {
	// 		authRequired: true,
	// 	},
	// 	props: (route) => ({
	// 		onlyPublished: false,
	// 		onlyDraft: true,
	// 	}),
	// },
	{
		path: '/kitchens',
		name: 'manageKitchens',
		component: () => import('@views/kitchens/manage.vue'),
		meta: {
			authRequired: true,
		},
	},
	{
		path: '/tags',
		name: 'Tags',
		component: () => import('@views/tags/manage.vue'),
		meta: {
			authRequired: true,
		},
	},
	{
		path: '/healths',
		name: 'Healths',
		component: () => import('@views/healths/manage.vue'),
		meta: {
			authRequired: true,
		},
	},
	{
		path: '/diets',
		name: 'Diets',
		component: () => import('@views/diets/manage.vue'),
		meta: {
			authRequired: true,
		},
	},
	{
		path: '/metrics',
		name: 'Metrics',
		component: () => import('@views/metrics/manage.vue'),
		meta: {
			authRequired: true,
		},
	},
	{
		path: '/ingredient-categories',
		name: 'Ingredient Categories',
		component: () => import('@views/ingredient-categories/manage.vue'),
		meta: {
			authRequired: true,
		},
	},
	{
		path: '/brands',
		name: 'Brands',
		component: () => import('@views/brands/manage.vue'),
		meta: {
			authRequired: true,
		},
	},
	{
		path: '/plan-categories',
		name: 'Plan Categories',
		component: () => import('@views/platelist-categories/manage.vue'),
		meta: {
			authRequired: true,
		},
	},
	{
		path: '/404',
		name: '404',
		component: () => import('@views/_404.vue'),
		// Allows props to be passed to the 404 page through route
		// params, such as `resource` to define what wasn't found.
		props: true,
	},
	// Redirect any unmatched routes to the 404 page. This may
	// require some server configuration to work in production:
	// https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
	{
		path: '/:pathMatch(.*)*',
		redirect: '404',
	},
];
