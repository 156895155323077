<script>
import Toast from 'primevue/toast';
import ConfirmDialog from 'primevue/confirmdialog';

export default {
	components: {
		Toast,
		ConfirmDialog,
	},
};
</script>

<template>
	<Toast />
	<ConfirmDialog></ConfirmDialog>
	<!--
	Even when routes use the same component, treat them
	as distinct and create the component again.
	-->
	<RouterView :key="$route.fullPath" />
</template>

<!-- This should generally be the only global CSS in the app. -->
<style lang="scss">
@use '@design' as *;
@use './scss/global-app-styles.scss';

// Design variables and utilities from src/design.

*,
*::before,
*::after {
	box-sizing: border-box;
}

#app {
	@extend %typography-small;

	height: 100%;
}

// ===
// Base element styles
// ===

a,
a:visited {
	color: $color-link-text;
}

h1 {
	@extend %typography-xxlarge;
}

h2 {
	@extend %typography-xlarge;
}

h3 {
	@extend %typography-large;
}

h4 {
	@extend %typography-medium;
}

h5,
h6 {
	@extend %typography-small;
}

// ===
// Vendor
// ===

#nprogress .bar {
	background: $color-link-text;
}
</style>
