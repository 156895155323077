<script>
export default {
	emits: {
		'update:modelValue': null,
	},
	props: {
		type: {
			type: String,
			default: 'text',
		},
		modelValue: {
			type: [String, Number],
			default: '',
		},
	},
};
</script>

<template>
	<input :type="type" :class="$style.input" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" v-bind="$attrs" autocomplete="off" />
</template>

<style lang="scss" module>
@use '@design' as *;

.input {
	@extend %typography-small;

	display: block;
	width: 100%;
	padding: $size-input-padding-vertical $size-input-padding-horizontal;
	margin-bottom: $size-grid-padding;
	line-height: 1;
	border: $size-input-border solid $color-input-border;
	border-radius: $size-input-border-radius;
}
</style>
