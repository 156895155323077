import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './app.vue';
import router from '@router';
import appConfig from '@src/app.config.ES6';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import PrimeVue from 'primevue/config';
//https://github.com/nuxt/vue-meta/tree/next?tab=readme-ov-file
import { createMetaManager, plugin as metaPlugin } from 'vue-meta';
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';
import Tooltip from 'primevue/tooltip';
import BaseIcon from './components/_base-icon.vue';
import BaseButton from './components/_base-button.vue';
import BaseLink from './components/_base-link.vue';
import BaseInput from './components/_base-input.vue';
import { version } from '../package.json';
import 'primevue/resources/themes/aura-light-green/theme.css';

const app = createApp(App);
if (appConfig.NODE_ENV === 'production' || appConfig.NODE_ENV === 'development') {
	Bugsnag.start({
		apiKey: appConfig.VUE_APP_BUGSNAG_KEY,
		notifyReleaseStages: ['production', 'staging'],
		plugins: [new BugsnagPluginVue()],
		releaseStage: appConfig.VUE_APP_BUGSNAG_RELEASE,
		appVersion: version,
	});
}
const bugsnagVue = Bugsnag.getPlugin('vue');

app.config.devtools = appConfig.NODE_ENV !== 'production';

// If running inside Cypress...
if (window.Cypress) {
	// Ensure tests fail when Vue emits an error.
	app.config.errorHandler = window.Cypress.cy.onUncaughtException;
} else {
	app.config.errorHandler = (err) => {
		if (appConfig.NODE_ENV === 'production' || appConfig.NODE_ENV === 'development') {
			// Track the native JS error
			Bugsnag.notify(err);
		}
	};
}
const metaManager = createMetaManager();
const pinia = createPinia();
// Define a global action handler
pinia.use(({ store }) => {
	// Automatically run the `init` action for every store,
	// if one exists.
	if (store.init) {
		store.init();
	}
});
app
	.use(pinia)
	.use(router)
	.use(PrimeVue)
	.use(bugsnagVue)
	.use(ToastService)
	.use(ConfirmationService)
	.use(metaManager)
	.use(metaPlugin, {
		// optional, only needed for OptionsAPI (see below)
		keyName: 'page', // The component option name that vue-meta looks for meta info on.
	})
	.component('BaseButton', BaseButton)
	.component('BaseIcon', BaseIcon)
	.component('BaseLink', BaseLink)
	.component('BaseInput', BaseInput)
	.directive('tooltip', Tooltip)
	.mount('#app');

// If running inside Cypress...
if (window.Cypress) {
	// Attach the app to the window, which can be useful
	// for manually setting state in Cypress commands
	// such as `cy.logIn()`.
	window.__app__ = app;
}
