import usePublicStore from '@state/public.js';

export default [
	{
		path: '/recipe/:recipeId',
		name: 'recipe-details',
		component: () => import('@views/public/recipe.vue'),
		meta: {
			authRequired: false,
			beforeResolve(routeTo, routeFrom, next) {
				const publicStore = usePublicStore();
				publicStore
					.getPublicRecipe({ recipeId: routeTo.params.recipeId })
					.then((recipe) => {
						routeTo.params.recipe = recipe;
						routeTo.params.error = null;
						// Continue to the route.
						next();
					})
					.catch((error) => {
						routeTo.params.recipe = null;
						routeTo.params.error = error;
						// Continue to the route.
						next();
					});
			},
		},
		props: (route) => ({
			recipe: route.params.recipe,
			error: route.params.error,
		}),
	},
	{
		path: '/macroWizard/:userAccessToken',
		name: 'macro-wizard',
		component: () => import('@views/public/macro-wizard.vue'),
		meta: {
			authRequired: false,
			beforeResolve(routeTo, routeFrom, next) {
				const publicStore = usePublicStore();
				publicStore
					.getUserProfile({ userAccessToken: routeTo.params.userAccessToken })
					.then((userProfile) => {
						routeTo.params.profile = userProfile;
						routeTo.params.error = null;
						// Continue to the route.
						next();
					})
					.catch((error) => {
						routeTo.params.profile = null;
						routeTo.params.error = error;
						// Continue to the route.
						next();
					});
			},
		},
		// Set the user from the route params, once it's set in the
		// beforeResolve route guard.
		props: (route) => ({
			profile: route.params.profile,
			error: route.params.error,
			userAccessToken: route.params.userAccessToken,
		}),
	},
];
