// App-specific config using modern ES2015+ export...
// For teh rest of the system EXCEPT for vue.config.js
export default {
	title: 'Menud Web App - production',
	description: 'Menud - production',
	NODE_ENV: 'production',
	API_BASE_URL: 'https://app.menud.co',
	BRANCH_KEY: 'key_live_diB8lUXePM5xrzYp3vmb1chmEraDDok0',
  VUE_APP_BUGSNAG_RELEASE: "production",
  VUE_APP_BUGSNAG_KEY: "86b730843836bdc4aced1b67a26438a5"
};
