import axios from 'axios';
import environment from '@src/environment';
import { defineStore } from 'pinia';

const useAuthStore = defineStore('auth', {
	state: () => ({
		currentUser: getSavedState('auth.currentUser'),
		lastValidationDate: null,
		cached: [],
		clientNavGroups: {
			Menud: [
				{
					title: 'Ingredients',
					options: [
						{
							name: 'manageIngredient',
							title: 'Manage Ingredients',
						},
					],
				},
				{
					title: 'Ingredient Mappings',
					options: [
						{
							name: 'internalMappedIngredients',
							title: 'Internal Mappings',
						},
						{
							name: 'externalMappedIngredients',
							title: 'External Mappings',
						},
					],
				},
				{
					title: 'Recipes',
					options: [
						{
							name: 'managedRecipes',
							title: 'Manage Recipes',
							children: [
								{
									name: 'publishedRecipes',
									title: 'Published',
								},
								{
									name: 'draftRecipes',
									title: 'Drafts',
								},
								{
									name: 'flaggedRecipes',
									title: 'Flagged',
								},
								{
									name: 'archivedRecipes',
									title: 'Archived',
								},
							],
						},
					],
				},
				{
					title: 'Plans',
					options: [
						{
							name: 'managePlans',
							title: 'Manage Platelists',
						},
					],
				},
				{
					title: 'Kitchens',
					options: [
						{
							name: 'manageKitchens',
							title: 'Manage Kitchens',
						},
					],
				},
				{
					title: 'Taxonomy',
					options: [
						{
							name: 'Tags',
							title: 'Tags',
						},
						{
							name: 'Healths',
							title: 'Healths',
						},
						{
							name: 'Diets',
							title: 'Diets',
						},
						{
							name: 'Metrics',
							title: 'Metrics',
						},
						{
							name: 'Ingredient Categories',
							title: 'Ingredient Categories',
						},
						{
							name: 'Plan Categories',
							title: 'Platelist Categories',
						},
						{
							name: 'Brands',
							title: 'Brands',
						},
					],
				},
			],
			RecipeInput: [
				{
					title: 'Recipes',
					options: [
						{
							name: 'managedRecipes',
							title: 'Manage Recipes',
							children: [
								{
									name: 'publishedRecipes',
									title: 'Published',
								},
								{
									name: 'draftRecipes',
									title: 'Drafts',
								},
								{
									name: 'flaggedRecipes',
									title: 'Flagged',
								},
								{
									name: 'archivedRecipes',
									title: 'Archived',
								},
							],
						},
					],
				},
				{
					title: 'Taxonomy',
					options: [
						{
							name: 'Tags',
							title: 'Tags',
						},
					],
				},
			],
			NotMenudDefault: [
				{
					title: 'Recipes',
					options: [
						{
							name: 'managedRecipes',
							title: 'Manage Recipes',
							children: [
								{
									name: 'publishedRecipes',
									title: 'Published',
								},
								{
									name: 'draftRecipes',
									title: 'Drafts',
								},
								{
									name: 'flaggedRecipes',
									title: 'Flagged',
								},
								{
									name: 'archivedRecipes',
									title: 'Archived',
								},
							],
						},
					],
				},
				{
					title: 'Plans',
					options: [
						{
							name: 'managePlans',
							title: 'Manage Plans',
						},
					],
				},
				{
					title: 'Taxonomy',
					options: [
						{
							name: 'Tags',
							title: 'Tags',
						},
						{
							name: 'Plan Categories',
							title: 'Plan Categories',
						},
					],
				},
			],
		},
		clientProfileLogo: {
			Menud: 'https://res.cloudinary.com/menud/image/upload/v1560982217/logo_fi9l3p.png',
			MenudTest: 'https://res.cloudinary.com/menud/image/upload/v1560982217/logo_fi9l3p.png',
			RecipeInput: 'https://res.cloudinary.com/menud/image/upload/v1560982217/logo_fi9l3p.png',
			CleanEating: 'https://res.cloudinary.com/menud/image/upload/v1560982069/CleanEatingMealsLogo_qointb.png',
			Massy: 'https://res.cloudinary.com/menud/image/upload/v1571849865/Saas_Clients/LOGO-1-rose.png',
			LiftByCass: 'https://res.cloudinary.com/menud/image/upload/v1571849865/Saas_Clients/LiftWithCass_Icon_FInal.png',
			WorkoutsByGabriela: 'https://res.cloudinary.com/menud/image/upload/v1571849867/Saas_Clients/Workouts_By_Gabriela_App_Icon.png',
			ZBody: 'https://res.cloudinary.com/menud/image/upload/v1571849865/Saas_Clients/Zbody_App_Icon.png',
			'ZBody-CT': 'https://res.cloudinary.com/menud/image/upload/v1571849865/Saas_Clients/Zbody_App_Icon.png',
			TrainWithLyzabeth: 'https://res.cloudinary.com/menud/image/upload/v1571849867/Saas_Clients/Train_With_Lyzabeth_App_Icon.png',
			FitByValen: 'https://res.cloudinary.com/menud/image/upload/v1571849867/Saas_Clients/FitByValen_1024_1.png',
			Conqherfit: 'https://res.cloudinary.com/menud/image/upload/v1571849866/Saas_Clients/Sami_B_Icon-03.png',
			BodyByAnita: 'https://res.cloudinary.com/menud/image/upload/v1571849867/Saas_Clients/Body_by_Anita_logo-08.jpg',
			CurvyAndCut: 'https://res.cloudinary.com/menud/image/upload/v1571849867/Saas_Clients/Curvy_and_Cut_Final-01.png',
			MyTrainerCarmen: 'https://res.cloudinary.com/menud/image/upload/v1571849868/Saas_Clients/MTC_Icon_new.png',
			SouthBeachDiet: 'https://res.cloudinary.com/menud/image/upload/v1572010783/Saas_Clients/SouthBeachDietLogo.png',
			PrettyMuscles: 'https://res.cloudinary.com/menud/image/upload/v1574371500/sigimg0-ID-681b2926-67a6-474c-e680-2cf5614d0644_hhrroe.png',
		},
	}),

	getters: {
		// Whether the user is currently logged in.
		loggedIn(state) {
			return !!state.currentUser;
		},

		isAdmin(state) {
			if (state.currentUser) {
				return !!state.currentUser.user.isAdmin;
			} else {
				return false;
			}
		},
	},

	actions: {
		SET_CURRENT_USER(newValue) {
			this.currentUser = newValue;
			saveState('auth.currentUser', newValue);
			setDefaultAuthHeaders(this.$state);

			this.lastValidationDate = new Date();
		},
		CACHE_USER(newUser) {
			this.cached.push(newUser);
		},
		// This is automatically run in `src/state/store.js` when the app
		// starts, along with any other actions named `init` in other modules.
		init() {
			setDefaultAuthHeaders(this.$state);
			this.validate();
		},

		// Logs in the current user.
		logIn({ username, password } = {}) {
			if (this.loggedIn) return this.validate();

			return axios.post(environment.ADMIN_LOGIN_URL, { username, password }).then((response) => {
				const user = response.data;
				this.SET_CURRENT_USER(user);
				return user;
			});
		},

		// Logs out the current user.
		logOut() {
			this.SET_CURRENT_USER(null);
		},

		// Validates the current user's token and refreshes it
		// with new data from the API.
		validate() {
			if (!this.currentUser) return Promise.resolve(null);

			return axios
				.post(environment.VALIDATE_AUTH_TOKEN_URL)
				.then(() => {
					return this.currentUser;
				})
				.catch((error) => {
					if (error.response) {
						this.SET_CURRENT_USER(null);
					}
					return null;
				});
		},
	},
	fetchUser({ username }) {
		// 1. Check if we already have the user as a current user.
		if (this.currentUser && this.currentUser.username === username) {
			return Promise.resolve(this.currentUser);
		}

		// 2. Check if we've already fetched and cached the user.
		const matchedUser = this.cached.find((user) => user.name === username);
		if (matchedUser) {
			return Promise.resolve(this.currentUser);
		}

		// 3. Fetch the user from the API and cache it in case
		//    we need it again in the future.
		return axios.get(`/api/users/${username}`).then((response) => {
			const user = response.data;
			this.CACHE_USER(user);
			return user;
		});
	},
});

export default useAuthStore;

// ===
// Private helpers
// ===

function getSavedState(key) {
	return JSON.parse(window.localStorage.getItem(key));
}

function saveState(key, state) {
	window.localStorage.setItem(key, JSON.stringify(state));
}

function setDefaultAuthHeaders(state) {
	axios.defaults.headers.common.Authorization = state.currentUser ? state.currentUser.id : '';

	// axios.defaults.headers.common.clientName = 'RecipeInput';
	axios.defaults.headers.common.clientName = state.currentUser && state.currentUser.client ? state.currentUser.client.name : '';
}
