import axios from 'axios';
import environment from '@src/environment';
import { defineStore } from 'pinia';

const usePublicStore = defineStore('public', {
	actions: {
		getPublicRecipe({ recipeId } = {}) {
			if (recipeId) {
				return axios
					.get(environment.PUBLIC_RECIPE_BY_ID_URL.replace(':id', recipeId))
					.then((response) => {
						return response.data;
					})
					.catch(() => {
						return { error: 'Unable to retrieve the requested recipe' };
					});
			} else {
				return { error: 'Your request cannot be processed' };
			}
		},

		getUserProfile({ userAccessToken } = {}) {
			if (userAccessToken) {
				return axios
					.get(environment.GET_USER_PROFILE_URL, { headers: { Authorization: userAccessToken } })
					.then((response) => {
						return response.data;
					})
					.catch(() => {
						return { error: 'Unable to get user profile' };
					});
			} else {
				return { error: 'Your request cannot be processed' };
			}
		},

		updateUserProfile({ userAccessToken, data } = {}) {
			if (userAccessToken && data) {
				return axios
					.post(environment.UPDATE_USER_PROFILE_URL, data, { headers: { Authorization: userAccessToken } })
					.then((response) => {
						return response.data;
					})
					.catch(() => {
						return { error: 'Unable to update user profile' };
					});
			} else {
				return { error: 'Your request cannot be processed' };
			}
		},
	},
});

export default usePublicStore;
